.invoice-table-th {
    background-color: #a1bfa1 !important;
    color: rgb(30, 30, 45);
    font-size:"13px";
    border: 0 !important;
    border-right:1px solid black !important;
    padding:10px 10px
}
.invoice-table-th-pdf {
    background-color: #a1bfa1 !important;
    color: rgb(30, 30, 45);
    font-size:"12px";
    border: 0 !important;
    border-right:1px solid black !important;
    padding:5px
}

.invoice-table-th-last-child{
    background-color: #a1bfa1 !important;
    color: rgb(30, 30, 45);
    font-size:"13px";
    border: 0 !important;
    padding:10px 10px;
}